<template>
  <v-main class="mt-5">
    <div class="mb-5 mt-5 pr-5 pl-5">
      <h2 class="mb-5 mt-5 pt-5">Trainees</h2>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div
        style="position:absolute;margin:0 auto;left:50%,right:50%;"
        v-if="loading"
      >
        Loading...
      </div>
      <downloadexcel
        class="v-btn v-btn--is-elevated v-btn--has-bg v-btn--router theme--light v-size--default"
        :fetch="fetchData"
        :fields="json_fields"
        name="MiladyTraineeList.xls"
        :before-generate="startDownload"
        :before-finish="finishDownload"
      >
        Download Excel
      </downloadexcel>
      <v-progress-circular
        :size="30"
        v-show="showProgress"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <v-row class="mt-5">
        <v-text-field
          class="mt-4"
          placeholder="Search Name,Phone,Organization"
          v-model="n_search"
          prepend-icon="mdi-magnify"
          @change="getTraineeListFromApi()"
          clearable
        >
          Search User
        </v-text-field>
        <v-col>
          <v-select
            :items="genderTypes"
            item-text="name"
            item-value="value"
            label="Gender"
            v-model="n_gender"
            @change="getTraineeListFromApi()"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="maritalStatusTypes"
            item-text="name"
            item-value="value"
            label="Marital Status"
            v-model="n_marital_status"
            @change="getTraineeListFromApi()"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="ageList"
            item-text="name"
            item-value="value"
            label="Age"
            v-model="n_age"
            @change="getTraineeListFromApi()"
            required
          ></v-select>
        </v-col>
        <v-col v-if="n_country_code == '' || n_country_code == 'MM'">
          <v-select
            :items="stateRegions"
            item-text="name"
            item-value="value"
            label="State Region"
            v-model="n_state_region"
            @change="getTraineeListFromApi()"
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="countries"
            item-text="name"
            item-value="value"
            label="Country"
            v-model="n_country_code"
            @change="getTraineeListFromApi()"
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="providerTypeList"
            item-text="name"
            item-value="value"
            label="Provider Type"
            v-model="n_providerType"
            @change="getTraineeListFromApi()"
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="pYears"
            item-text="name"
            item-value="value"
            label="Year"
            v-model="n_year"
            @change="getTraineeListFromApi()"
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" sm="6">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="fromDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu1 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu1.save(fromDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" sm="6">
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="toDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="toDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu2.save(toDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-btn @click="getTraineeListFromApi()" class="secondary">Search</v-btn> &nbsp;
          <v-btn @click="resetForm()" dark class="grey">Reset Form</v-btn>
        </v-col>
      </v-row>
      <v-layout child-flex>
        <v-data-table
          dense
          :headers="headers"
          :items="trainees"
          :options.sync="traineeListOptions"
          :server-items-length="totalTrainee"
          :loading="traineeListLoading"
          loading-text="Loading... Please wait"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="showDetail(item.userId)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-layout>
    </div>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import {
  FETCH_PROVIDER_TYPE_LIST,
  FETCH_TRAINEE_LIST,
  FETCH_COUNTRY_LIST,
} from "../store/actions.type";
import downloadexcel from "vue-json-excel";
import {countryList,userTypes,providerTypes, genderTypes,ageList,maritalStatusTypes,stateRegions, pYears}  from "../common/ref_data";
export default {
  name: "TraineeList",
  components: {
    downloadexcel,
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      trainees: [],
      info: null,
      totalTrainee: 0,
      traineeListOptions: {},
      n_search: "",
      n_gender: null,
      n_marital_status: null,
      n_age: null,
      n_year: null,
      n_state_region: null,
      n_country_code: null,
      n_userType: null,
      n_providerType: null,
      fromDate: null,
      toDate: null,
      showProgress: false,
      loading: true,
      errored: false,
      countries: countryList,
      traineeListLoading: true,
      userListParam: {
        type: "",
        search: "",
        page: 0,
        limit: 20,
        sortBy: [],
        sortDesc: [],
      },
      userTypes: userTypes,
      ageList: ageList,
      genderTypes: genderTypes,
      maritalStatusTypes: maritalStatusTypes,
      stateRegions: stateRegions,
      providerTypes: providerTypes,
      pYears: pYears,
      providerTypeList: [],
      headers: [
        {
          text: "Registered Date",
          align: "start",
          sortable: true,
          value: "createddate",
        },
        { text: "Name", value: "name" },
        { text: "Gender", value: "gender" },
        { text: "MaritalStatus", value: "marital_status" },
        { text: "State/Region", value: "state_region" },
        { text: "Provider Type", value: "provider_type" },
        { text: "Organization", value: "organization" },
        { text: "PreTest Score", value: "preTestScore" },
        { text: "PostTest Attempt Count", value: "postTestAttemptCount" },
        { text: "PostTest Last Score", value: "postTestScore" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      json_fields: {
        UserID: "userId",
        createddate: "createddate",
        MobileNo: "phone",
        Gender: "gender",
        Age: "age",
        MaritalStatus: "marital_status",
        StateRegion: "state_region",
        UserType: "user_type",
        ProviderType: "provider_type",
        Organization: "organization",
        PretestTaken: "pretestTaken",
        PreTestAttemptDate: "preTestAttemptDate",
        PreTestScore: "preTestScore",
        Last_PostTestScore: "postTestScore",
        PostTestPassedDate: "postTestPassedDate",
        PostTestAttemptCount: "postTestAttemptCount",
      },
    };
  },
  computed: {},
  watch: {
    traineeListOptions: {
      handler() {
        this.getTraineeListFromApi();
      },
      deep: true,
    },
  },
  created() {
    this.getProviderTypeList();
    this.getCountryList();
  },
  filters: {
    percentageScore: function (value) {
      if (!value) return "";
      return Math.round((value / 10) * 100);
    },
  },
  methods: {
    getCountryList(){
      var payload = { userType : '' };
      var newCountryList = [{ name: "Select Country", value: '--Select--' }];
      this.$store
        .dispatch(FETCH_COUNTRY_LIST,payload)
        .then((data) => {
          if(data.length> 0){
            for(let i = 0; i < data.length; i++){
              newCountryList.push(data[i]);
            }
          }
        }).finally(() => {
          this.countries = newCountryList;
        });
    },
    resetForm(){
      this.n_age = null;
      this.n_state_region = null;
      this.n_gender = null;
      this.n_country_code = null;
      this.n_marital_status = null;
      this.n_year = null;
      this.n_month = null;
      this.n_providerType = null;
      this.fromDate = null;
      this.toDate = null;
      this.getTraineeListFromApi();
    },
    showDetail(userID) {
      localStorage.setItem("selectedTrainee", userID);
      this.$router.push({
        name: "TraineeDetail",
        params: {
          userId: userID,
        },
      });
    },
    getTraineeListFromApi() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.traineeListOptions;
      this.userListParam.page = page;
      this.userListParam.limit = itemsPerPage;
      this.userListParam.sortBy = sortBy;
      this.userListParam.sortDesc = sortDesc;
      this.userListParam.term = this.n_search;
      this.userListParam.option = {
        user_type: this.n_userType,
        age: this.n_age,
        state_region: this.n_state_region,
        country_code: this.n_country_code,
        gender: this.n_gender,
        marital_status: this.n_marital_status,
        provider_type: this.n_providerType,
        year: this.n_year,
        from_date: this.fromDate,
        to_date: this.toDate,
        is_excluding_mm: false,
      };
      if(this.n_country_code != 'MM'){
        this.userListParam.option.is_excluding_mm = true
      }
      this.traineeListLoading = true;
      console.log(this.userListParam);
      this.$store
        .dispatch(FETCH_TRAINEE_LIST, this.userListParam)
        .then((data) => {
          this.trainees = data.trainees;
          this.totalTrainee = data.total;
          this.traineeListLoading = false;
          this.loading = false;
          console.log(this.trainees);
        })
        .catch(() => {
          this.traineeListLoading = false;
          this.loading = false;
          this.errored = true;
        })
        .finally(() => (this.traineeListLoading = false));
    },
    getProviderTypeList() {
      this.$store
        .dispatch(FETCH_PROVIDER_TYPE_LIST)
        .then((data) => {
          this.loading = false;
          if (data != null) {
            this.providerTypeList.push({
              name: "All Provider Types",
              value: null,
            });
            for (var i = 0; i < data.length; i++) {
              this.providerTypeList.push({
                name: data[i],
                value: data[i],
              });
            }
          }
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
        });
    },
    fetchData() {
      this.showProgress = true;
      return this.trainees;
    },
    startDownload() {
      this.showProgress = true;
    },
    finishDownload() {
      this.showProgress = false;
    },
  },
};
</script>
../common/ref_data